import React, { useState } from 'react';
import axios from 'axios';

const OtpRegistration = () => {

    const [inputValue, setInputValue] = useState({
        enterNumber:'',
        enterOtp:'',
        otpEntered:false
    })
    const baseUrl=process.env.REACT_APP_API_URL
    const onSubmitNumber = async(e) => {
        e.preventDefault();
        try {
            const url = `${baseUrl}/auth/register/otp`
            
        const response = await axios.post(url,{otpMedium: inputValue.enterNumber});
        console.log(response);
        
        setInputValue({...inputValue,otpEntered:true})
        } catch (error) {
            console.log(error.response.data)
        }   
    }

    const onSubmitOtp = async(e) => {
        e.preventDefault();
        try {
            const url=`${baseUrl}/auth/register/verify-otp`
        const response = await axios.post(url,{
            otpMedium
            : inputValue.enterNumber
            ,
            otp
            :
             inputValue.enterOtp
            })
        console.log(response)
        } catch (error) {
            console.log(error)
        }    
    }

    const handleInputEvent = (e) => {
        setInputValue({...inputValue,[e.target.id]:e.target.value})
        
        console.log(inputValue.enterNumber)
    }

    const enterMobileNumber = () => {
        return(
        <form onSubmit={onSubmitNumber}>
            <div>
                <label htmlFor='enterNumber'>Register</label>
                <input placeholder="Mail/Phone" type="text" value={inputValue.enterNumber} onChange={handleInputEvent} id="enterNumber"/>
            </div>
            <input type="submit" />
        </form>
        )
    }

    const enterOtp = () => {
        return(
        <form onSubmit={onSubmitOtp}>
            <div>
                <label htmlFor='enterOtp'>Enter Your OTP</label>
                <input type="text" value={inputValue.enterOtp} onChange={handleInputEvent} id="enterOtp"/>
            </div>
            <input type="submit"/>
        </form>
        )
    }

 return(
    <>     
    <h1>SignUp</h1> 
       <div>{enterMobileNumber()}</div>
        {inputValue.otpEntered &&
            <div>{enterOtp()}</div>
        }
    </>
 )
}

export default OtpRegistration