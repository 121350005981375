import axios from 'axios';
import React, { useState } from 'react';
import Cookies from 'js-cookie' 
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
const UserLoginPage = () =>{
    const [inputValues, setInputValues] = useState({
            "mailOrMobile": '',
            "password": ''
        })

        const navigate = useNavigate()
    const baseUrl=process.env.REACT_APP_API_URL

    const handleOnChangeInput = (e) => {
        setInputValues({
            ...inputValues, [e.target.id] : e.target.value
        })
     }

    const onSubmitSuccess = (jwtToken) => {
        console.log(jwtToken)
        Cookies.set('jwtToken',jwtToken)
        navigate('/userPasswordReset')
    }

     const handleLoginSubmit = async(e)=>{
        e.preventDefault()
        console.log(inputValues)
        try {
            const url=`${baseUrl}/auth/login` 

            const response = await axios.post(url, inputValues)
            if (response.status === 200){
              onSubmitSuccess(response.data.jwtToken)
            }
        } catch (error) {
            console.log(error)
        }
     }

    return(
        <div>
        <h1>Login</h1>
            <form onSubmit={handleLoginSubmit}>
                <div>
                    <label htmlFor="mailOrMobile">Mail/ Mobile</label>
                    <input type="text" id="mailOrMobile" value={inputValues.mailOrMobile} onChange={handleOnChangeInput}/>
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input type="text" id="password" value={inputValues.password} onChange={handleOnChangeInput}/>
                </div>
                <input type='submit' />
            </form>
            <Link to="/userPasswordReset">Reset password</Link><br/>
            <Link to="/OtpLogin">Login With OTP</Link>
        </div>
    )
}

export default UserLoginPage