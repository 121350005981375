import axios from 'axios';
import React, { useState } from 'react';


const OtpLogin = () => {
    const[userInput, setUserInput] = useState({
       enterNumber:"", 
       enterOtp:"", 
       userEntered: false
    })

    const baseUrl = process.env.REACT_APP_API_URL

    const submitNumber = async(e) => {
        e.preventDefault();
        try {
            const url=`${baseUrl}/auth/login/otp`
        const response = await axios.post(url,{otpMedium:userInput.enterNumber} )
        console.log(response) 
        setUserInput({...userInput, userEntered:true})
        } catch (error) {
            console.log(error)
        }
    } 

    const onSubmitOtp = async(e) =>{
        e.preventDefault()
        try {
            const url = `${baseUrl}/auth/login/verify-otp`
        const body = {otpMedium:userInput.enterNumber, otp:userInput.enterOtp}
        const response = await axios.post(url, body)
        console.log(response)
        } catch (error) {
            console.log(error)
        }
        
    }

    const handleInputValue = (e) => {
        setUserInput({...userInput, [e.target.id]:e.target.value})
    }

    const enterNumberInput = () => {
        return(
            <>
                <form onSubmit={submitNumber}>
                <div>
                    <label htmlFor="enterNumber">Login</label>
                    <input placeholder='Mail/Mobile' id="enterNumber" type="text" onChange={handleInputValue} value={userInput.enterNumber} />
                    </div>
                    <input type='submit' />
                </form>
            </>
        )
    }

    const enterOtp = () => {
        return(
            <>
                <form onSubmit={onSubmitOtp}>
                    <label htmlFor='enterOtp'>Enter OTP</label>
                    <input id='enterOtp' value={userInput.enterOtp} onChange={handleInputValue} />
                    <input type='submit' />
                </form>
            </>
        )
    }

return(
    <>
    <h1>Login</h1>
        <div>{enterNumberInput()}</div>
        {userInput.userEntered&&<div>{enterOtp()}</div>}
    </>
)
}

export default OtpLogin