import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import axios from "axios"

const UserRegistrationPage=()=>{
    const [inputValues , setInputValues] = useState({ 
    "customerFirstName": "",
    "customerLastName": "",
    "customerMobileNum": "", 
    "customerEmail": "", 
    "customerPassword": ""
})

    const handleOnChangeInput = (e) => {
        setInputValues(
            {...inputValues , [e.target.id]:e.target.value})
    }

    const onSubmitSuccess = (jwtTocken) => {
        
    }

  const baseUrl=process.env.REACT_APP_API_URL

    const handleRegistration = async(e) => {
        try {
            e.preventDefault()
            const url=`${baseUrl}/auth/register`;
            const response = await axios.post(url,inputValues)
            if (response.status===201){
                onSubmitSuccess(response.data.jwtToken)
            }
            console.log(response)
        } catch (error) {
            console.log(error.response?.data.message)
        }
    }

  return(
    <>
    <h1>SignUp</h1>
    <form onSubmit={handleRegistration}>
        <div>
            <label htmlFor="customerFirstName">First Name</label>
            <input value={inputValues.customerFirstName} onChange={handleOnChangeInput} id="customerFirstName"/>
        </div>
        <div>
            <label htmlFor="customerLastName">Last Name</label>
            <input value={inputValues.customerLastName} onChange={handleOnChangeInput} id="customerLastName"/>
        </div>
        <div>
        <label htmlFor="customerMobileNum">Mobile Num</label>
        <input value={inputValues.customerMobileNum} onChange={handleOnChangeInput} id="customerMobileNum"/>
        </div>
        <div>
        <label htmlFor="customerEmail">Email</label>
        <input value={inputValues.customerEmail} onChange={handleOnChangeInput} id="customerEmail"/>
        </div>
        <div>
        <label htmlFor="customerPassword">Password</label>
        <input value={inputValues.customerPassword} onChange={handleOnChangeInput} id="customerPassword"/>
        </div>     
        <input type="submit"/>
    </form>
    <div style={{display: 'flex'}}>
    <p>register with otp </p><Link to='/register'><p>Click to proceed</p></Link>
    </div>
    <div style={{display: 'flex'}}>
    <p>Already Registered? </p><Link to='/userLoginPage'><p>Login</p></Link>
    </div>
    </>
  )
}

export default UserRegistrationPage
