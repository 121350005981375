import {Routes , Route} from 'react-router-dom'
import UserRegistrationPage from './UserPages/UserRegistrationPage';
import UserLoginPage from './UserPages/UserLoginPage';
import ResetPassword from './UserPages/ResetPassword';
import OtpRegistration from './UserPages/OtpRegistration';
import OtpLogin from './UserPages/OtpLogin';

import './App.css';

function App() {
  return (
  <Routes>
    <Route path="/" element={<UserRegistrationPage/>} />
    <Route path="/userLoginPage" element={<UserLoginPage />} />
    <Route path="/userPasswordReset" element={<ResetPassword />} />
    <Route path='/register' element={<OtpRegistration />} />
    <Route path="/OtpLogin" element={<OtpLogin />} />
  </Routes>
  );
}

export default App;
